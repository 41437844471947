html,
body {
  --custom-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --custom-bg-color: #fefefe;
  --custom-panel-color: #222;
  --custom-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
  --custom-color: #000000;
  --off-black-color: #393939;
  --color-brand1: #D31E53;
  --color-brand2: #5C4EA0;
  --color-brand-mid: #b63cbc;
  --color-brand1-light: #e286a8;
  --color-brand2-light: #8d8abe;
  --color-brand1-lightest: #eebecf;
  --color-brand2-lightest: #c9c7ef;
  --custom-color-secondary: #dddddd;
  --custom-color-disabled: #868686;
  --custom-border: 1px solid #333;
  --disabled-button: #ababab;
  --custom-border-radius: 5px;
  --custom-spacing: 5px;

  padding: 0;
  margin: 0;
  font-family: var(--custom-font-family);
  background-color: var(--custom-bg-color);
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

* {
  color: var(--custom-color);
  font-family: var(--custom-font-family);
  box-sizing: border-box;
}

html,
body,
#__next {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

/* Grid */

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
/* Ensure the main content area grows to take up available space */
.app-container > div {
  flex: 1;
}
.row {
  position: relative;
  width: 100%;
}
.row [class^='col'] {
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%;
}
.col-1-sm {
  width: 4.33%;
}
.col-2-sm {
  width: 12.66%;
}
.col-3-sm {
  width: 21%;
}
.col-4-sm {
  width: 29.33%;
}
.col-5-sm {
  width: 37.66%;
}
.col-6-sm {
  width: 46%;
}
.col-7-sm {
  width: 54.33%;
}
.col-8-sm {
  width: 62.66%;
}
.col-9-sm {
  width: 71%;
}
.col-10-sm {
  width: 79.33%;
}
.col-11-sm {
  width: 87.66%;
}
.col-12-sm {
  width: 96%;
}
.row::after {
  content: '';
  display: table;
  clear: both;
}
.hidden-sm {
  display: none;
}

@media only screen and (min-width: 33.75em) {
  /* 540px */
  .container {
    width: 80%;
  }
}

@media only screen and (min-width: 45em) {
  /* 720px */
  .col-1 {
    width: 4.33%;
  }
  .col-2 {
    width: 12.66%;
  }
  .col-3 {
    width: 21%;
  }
  .col-4 {
    width: 29.33%;
  }
  .col-5 {
    width: 37.66%;
  }
  .col-6 {
    width: 46%;
  }
  .col-7 {
    width: 54.33%;
  }
  .col-8 {
    width: 62.66%;
  }
  .col-9 {
    width: 71%;
  }
  .col-10 {
    width: 79.33%;
  }
  .col-11 {
    width: 87.66%;
  }
  .col-12 {
    width: 96%;
  }
  .hidden-sm {
    display: block;
  }
}

@media only screen and (min-width: 60em) {
  /* 960px */
  .container {
    width: 85%;
    max-width: 120rem;
  }
}

.top-gradient-line {
  height: 8px; /* Adjust the thickness of the line */
  background: linear-gradient(to right, var(--color-brand1-light), var(--color-brand2-light));
  width: 100%;
}
nav {
  background: linear-gradient(to right, var(--color-brand1), var(--color-brand2));
  max-height: 60px;
  overflow: hidden;
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.6);
  margin-bottom: 0px;
  z-index: 2; /* Ensure the nav is on top of other elements */
}
nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 10px 40px;
  margin: 0px;
  height: 100%; /* Makes ul height equal to its parent nav */
  flex: 1; /* Allows the ul to grow and fill available space */
  position: relative;
  box-sizing: border-box; /* Includes padding in the element's total width and height */
}

nav .icon-container {
  position: absolute;
  height: 100%;
  left: 50%; /* Center the icon */
  top: 50%; /* Center the icon vertically */
  transform: translateY(-50%) translateX(-50%); /* Adjust for the icon's height and width */
  display: inline-flex; /* Use flexbox for alignment */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  max-width: 20%;
}
nav .icon-container a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav .left-items, nav .right-items {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  gap: 15px;
}
nav .left-items {
  justify-content: flex-start; /* Aligns items to the left */
}
nav .right-items {
  justify-content: flex-end; /* Aligns items to the right */
}
nav .button.white {
  background-color: transparent;
  color: white;
  border-color: white;
  text-transform: uppercase;
  padding: 6px 10px;
  margin: 0px 20px;
  font-size: 0.9rem;
  opacity: 0.9;
  font-weight: 400;
}
nav .button.white:hover {
  background-color: white;
  color: var(--color-brand2);
  font-weight: 500;
  transition: 0.3s;
}
nav .button.white.left:hover {
  color: var(--color-brand1);
}
nav svg {
  width: 50px;
  height: 50px;
}
nav svg path {
  fill: white;
  opacity: 0.9;
}


/* Footer */
.footer {
  background: linear-gradient(to right, var(--color-brand1), var(--color-brand2));
  /*position: fixed;*/
  max-width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row;
  /*border-top: var(--custom-border);
  background-color: var(--custom-bg-color);*/
  padding: 1em 1em;
  color: white;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.4);
  flex-shrink: 0;
}
.footer-links {
  display: flex;
  gap: 10px;
}
.footer .link {
  margin: 0 1em;
  text-decoration: underline;
  color: white;
}
.footer-copyright {
  align-self: center;
}
.footer div {
  color: white;
  padding: var(--custom-spacing);
  display: flex;
  align-items: center;
  width: 100%;
}
.footer div > img {
  height: 20px;
  margin-left: 10px;
}
.footer > div:first-child {
  display: none;
}
.footer > div:nth-child(2) {
  justify-content: left;
}

/* Forms */

label {
  display: block;
  margin: 5px 0;
  color: var(--custom-color);
  font-size: 1rem;
  text-transform: uppercase;
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}

input {
  width: 100%;
  border-radius: 5px;
  border: var(--custom-border);
  padding: 8px;
  font-size: 0.9rem;
  background-color: var(--custom-bg-color);
  color: var(--custom-color);
}

input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}

input[disabled] {
  background-color: var(--custom-color-secondary);
  border-color: var(--custom-color-disabled);
  /*border-width: 2px;*/
  color: var(--custom-color-disabled);
}

.my-time-picker .react-datetime-picker__inputGroup__input {
  width: 80px;
  border: none;
  outline: none;
}
.my-time-picker .react-datetime-picker__inputGroup__input:nth-child(2) {
  width: 60px; /* adjust as needed */
  border: none;
  outline: none;
}

.settings-label {
  display: block;
  margin: 5px;
  margin-right: 20px;
  margin-top: 20px; /* Add space above the text */
  margin-bottom: 20px; /* Add space below the text */
  color: var(--custom-color);
  font-size: 2rem;
  text-transform: uppercase;
}

.label.clientsettings {
  display: block;
  margin: 5px 0;
  color: var(--custom-color);
  font-size: 2rem;
  text-transform: uppercase;
}

.settings-select {
  border-radius: 5px;
  border: var(--custom-border);
  padding: 8px;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

/* Utils */

.block {
  display: block;
  width: 100%;
}
.inline-block {
  display: inline-block;
  width: 100%;
}
.flex {
  display: flex;
}
.flex.column {
  flex-direction: column;
}
.flex.row {
  flex-direction: row;
}
.flex.flex-1 {
  flex: 1 1 0;
}
.flex-end {
  justify-content: flex-end;
}
.flex-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.text-sm {
  font-size: 0.8rem;
  font-weight: 300;
}
.text-right {
  text-align: right;
}
.font-light {
  font-weight: 300;
}
.opacity-half {
  opacity: 50%;
}

/* Button */

button,
.button {
  color: var(--custom-color);
  border: var(--custom-border);
  background-color: var(--custom-bg-color);
  display: inline-block;
  text-align: center;
  border-radius: var(--custom-border-radius);
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.button.primary {
  background-color: var(--color-brand2);
  border: 1px solid var(--color-brand2);
  color: white;
}

.button.secondary {
  background-color: red;
  /*background-color: var(--custom-bg-color);
  border: 1px solid var(--custom-border);*/
  border: 1px solid black;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button.secondary:hover {
  background-color: var(--custom-color-secondary);
}


/* Widgets */

.card {
  width: 100%;
  display: block;
  border: var(--custom-border);
  border-radius: var(--custom-border-radius);
  padding: var(--custom-spacing);
}

.avatar {
  border-radius: var(--custom-border-radius);
  overflow: hidden;
  max-width: 100%;
}
.avatar.image {
  object-fit: cover;
}
.avatar.no-image {
  background-color: #333;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
}

@media only screen and (min-width: 60em) {
  /* 960px */
  .footer > div:first-child {
    display: flex;
  }
  .footer > div:nth-child(2) {
    justify-content: center;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainHeader {
  width: 100%;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.avatarPlaceholder {
  border: var(--custom-border);
  border-radius: var(--custom-border-radius);
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-widget {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.form-widget > .button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #444444;
  transition: all 0.2s ease;
  color: white;
}

.form-widget .button:hover {
  background-color: #2a2a2a;
}

.form-widget .button.primary {
  background-color: var(--color-brand2);
  border: 2px solid var(--color-brand2);
  color: white;
  transition: 0.2s;
}

.form-widget .button.primary:hover {
  color: var(--color-brand2);
  background-color: var(--custom-bg-color);
  transition: 0.2s;
}

.form-widget .button.primary:disabled {
  background-color: var(--disabled-button);
  border: 2px solid var(--disabled-button);
  color: white;
  pointer-events: none;
}

.form-widget .button.secondary {
  background-color: var(--custom-bg-color);
  /*border: 1px solid var(--custom-border);*/
  border: 1px solid black;
  color: black;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 8px 13px;
  color: var(--off-black-color)
}

.form-widget .button.secondary:hover {
  background-color: var(--custom-color-secondary);
  transition: 0.1s;
}

.form-widget .button.secondary:disabled {
  background-color: var(--custom-bg-color);
  border: 1px solid var(--custom-color-secondary);
  color: var(--custom-color-secondary);
  cursor: not-allowed;
}
.form-widget .button.secondary:disabled i {
  color: var(--custom-color-secondary);
}

.block-button .button.white:disabled {
  background-color: var(--custom-bg-color);
  border-color: var(--custom-color-secondary);
  color: var(--custom-color-secondary);
  cursor: not-allowed;
}
.block-button .button.white svg {
  height: 1.03rem;
  width: auto; /* Maintain aspect ratio */
}
.block-button .button.white:disabled svg path {
  fill: var(--custom-color-secondary);
}
.block-button .button.white:disabled:hover svg path {
  fill: var(--custom-color-secondary);
}
.block-button .button.white:hover svg path {
  fill: var(--custom-bg-color);
}

.form-widget .button > .loader {
  width: 17px;
  animation: spin 1s linear infinite;
  filter: invert(1);
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.flexbox {
  display: flex;
  justify-content: left;
  align-items: center;
}
.flexbox-bottom {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}