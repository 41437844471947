/*
Specify colors
*/
:root {
    --primary-color: #007bff;
    --secondary-color: #6c757d;
    --success-color: #28a745;
    --info-color: #17a2b8;
    --warning-color: #ffc107;
    --danger-color: #dc3545;
    --light-color: #f8f9fa;
    --dark-color: #343a40;
    --white-color: #ffffff;
    --black-color: #000000;
    --gray-color: #b8bec2;
    --gray-dark-color: #586068;
    --gray-blue-color: #c7d4e2;
    --integration-color: #e9ebed;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.settings-container {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    width: 80%;
}
/* Specific adjustments for the Navbar if needed */
.navbar-class-name {
    margin: 0; /* Example to remove margin, replace .navbar-class-name with actual class */
}

.client-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin-bottom: 10px;
    margin-right: 30px;
    border: none;
    border-radius: 5px;
    background-color: white;
    width: 90%;
}

.client-box .left-items {
    display: flex;
    align-items: center;
    padding: 0px;
}
.delete-button {
    background-color: white;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    margin-right: 5px;
    margin-left: 5px;
    text-transform: uppercase;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 0px;
    border-radius: 5px;
}
.delete-button:hover {
    background-color: var(--secondary-color);
    color: white;
    cursor: pointer;
}
.delete-button i {
    font-size: 13px;
    color: var(--secondary-color);
}
.delete-button:hover i {
    color: white;
}
  
.client-box-editing {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
}

.client-name-box {
    padding: 7px 10px;
    /*margin-bottom: 10px;*/
    /*border: 1px solid #ddd;*/
    border-radius: 5px;
    /*background-color: rgb(234, 230, 239);*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    margin-right: 20px;
    /*margin-left: 10px;*/
}
.button.white.square {
    display: flex; /* Enables flexbox layout */
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
    background-color: transparent;
    height: 33px;
    width: 40px;
}
.button.white.square:hover {
    background-color: black;
}

.circular-button {
    border-radius: 50%; /* Makes the button circular */
    width: 25px;
    height: 25px;
    font-size: 13px; /* Sets the icon size */
    display: flex; /* Enables flexbox layout */
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
    padding: 0;
    border: none;
    background-color: transparent;
}

.circular-button i {
    border: none;
    color: var(--secondary-color);
    background-color: transparent;
}

.collapsible-content {
    overflow: hidden;
    transition: height 0.5s ease-out, opacity 0.5s ease-out;
    height: auto;
    opacity: 1;
}
.collapsible-content.collapsed {
    height: 0;
    opacity: 0;
    transition: height 0.5s ease-in, opacity 0.5s ease-in;
}
.collapsible-content.expanded {
    height: auto;
    opacity: 1;
    transition: height 0.5s ease-out, opacity 0.5s ease-out;
}

.table-container {
    overflow-x: auto; /* Adds horizontal scroll if needed */
    width: 100%;
}
.clients-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
}
.clients-table tr {
    border-bottom: 1px solid #ddd;
    width: 100%;
}
.clients-table th, .clients-table td {
    padding: 8px;
    overflow: hidden; /* Hides overflow content */
    text-overflow: ellipsis; /* Adds ellipsis to overflow text */
    white-space: nowrap; /* Prevents text from wrapping */
}
.clients-table thead {
    background-color: #f2f2f2;
}
.clients-table th {
    text-align: left;
    width: auto;
}
.clients-table tr:hover {
    background-color: #f5f5f5;
}

.clients-table .button-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.matter-box {
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    white-space: nowrap; /* Prevents the text from wrapping to a new line */
    overflow: hidden; /* Hides the overflow text */
    text-overflow: ellipsis; /* Adds an ellipsis to the overflow text */
}
  
.matter-box-editing {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: white;
}

.client-box input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgb(200, 200, 200);
    width: 200 px;
    max-width: 200px;
    margin: 5px;
    margin-left: 10px;
}
  
.client-box-editing input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
}
  
.matter-box input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: rgb(200, 200, 200);
    width: 100%;
}

.matter-box-editing input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: white;
    width: 100%;
}

.client-box input:focus {
    background-color: #eeeeee;
}

.matter-box input:focus {
    background-color: #eeeeee;
}

.settings-icon {
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 10%;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;

}
.settings-icon:hover {
    cursor: pointer;
    background-color: var(--gray-color);
}
.settings-icon.settings-open {
  background-color: var(--gray-color);
}

.settings-popup {
    position: relative;
}

.dropdown-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px; /* adjust as needed */
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/* Style the dropdown button */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown:hover {
    background-color: #f1f1f1;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/* Style the links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: var(--gray-color);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.integrations-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
    gap: 16px; /* Adjust the gap between grid items as needed */
}
.integration {
    border: solid 1px #ccc; /* Optional: Add a border around each integration item */
    padding: 16px;
    padding-right: 10px;
    padding-top: 10px;
    align-items: flex-start;
    border-radius: 5px;
    background-color: var(--integration-color);
    box-shadow:  4px 4px 10px 0px rgba(0,0,0,0.3);
    min-height: 100px;
}
.integration h3 {
    margin: 0;
}
.integration button.white {
    margin-left: 0px;
    margin: 0px;
    background-color: transparent;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    display: flex;
    border-width: 1.5px;
}

/* descriptions */
.desc.container {
    display: inline-flex;
    justify-content: start;
    align-items: center;
    margin: 0px;
}
input.desc {
    max-width: 20%;
    display: flex;
}
.desc.container label {
    background-color: var(--gray-blue-color);
    border-radius: 5px;
    padding: 7px;
    margin-left: 5px;
    margin-right: 5px;
}
.desc.container h3 {
    width: 20%;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 10px;
}

.activities-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.activity-item {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    word-break: break-word; /* Ensures long words break to fit within the box */
    max-width: fit-content; /* Prevents the item from stretching beyond the content */
}

.delete-button {
    background: none;
    border: none;
    color: red;
    font-size: 16px;
    cursor: pointer;
}