:root {
  --error-red: rgb(255, 58, 58);
  --error-red-bg: #ffcccc;
  --error-button-border: darkred;
  --error-button-border-light: #e38787;
  --hover-gray: #f0f0f0;
  --color-brand1: #D31E53;
  --border-radius: 5px;
  --select-width: 160px; /* Adjust this value as needed */
  --button-font-size: 0.9rem;
  --popup-background-color: rgb(216, 216, 216);
  --popup-disabled-text-color: rgb(139, 139, 139);
}

/* Entries page */

/* Custom select dropdown */
.select-container {
  position: relative;
  padding: 0;
}
.select-selected {
  color: black; /* Text color */
  padding: 8px 12px; /* Padding */
  border: 2px solid black; /* Border for the selected item */
  cursor: pointer; /* Cursor */
  user-select: none; /* Prevent text selection */
  border-radius: var(--border-radius); /* Rounded edges for the selected item */
  width: var(--select-width);
  text-transform: uppercase;
  font-size: var(--button-font-size);
}
.select-items {
  position: absolute;
  background-color: white;
  border: 2px solid transparent; /* Transparent border for the options */
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 99;
  width: var(--select-width);
  text-transform: uppercase;
  font-size: var(--button-font-size);
}
.select-items div, .select-selected {
  color: black; /* Text color */
  padding: 8px 12px; /* Padding */
  cursor: pointer; /* Cursor */
  user-select: none; /* Prevent text selection */
}
.select-items div {
  border-radius: 0px; /* No rounded edges for the options */
}
.select-items div:hover, .select-selected:hover {
  background-color: black;
  color: white;
}

.button-group {
  display: flex;
  align-items: center; /* This ensures that the items are vertically centered */
  justify-content: center; /* This centers the items horizontally within the container, you can adjust this as needed */
  overflow: hidden; /* This prevents the buttons from overflowing the container */
  margin-top: 0px;
  margin-bottom: 0px;
}
/*.button-group > span {
  display: flex;
  align-items: center;
}*/

/* styles for entry table */
table {
  width: 100%;
  border-collapse: separate; /* Change this from collapse to separate */
  border-spacing: 10px; /* Add some spacing between cells */
}

td {
  border: 0px solid #ddd;
  padding: 0px;
  text-align: left;
  color: black; /* Add this line to change the font color to black */
  /* border-radius: 10px; Add this line to make each element a rounded rectangle */
  /*background-color: #FFEEF0;*/
}

th {
  padding: 8px;
  text-align: left;
  color: black; /* Add this line to change the font color to black */
}
.timespent-header {
  width: 100px;
}
.client-header {
  width: 200px;
}
.matter-header {
  width: 200px;
}
.activity-header {
  width: 200px;
}
.description-header {
  width: 400px;
}
table .button.white {
  margin: 0px;
}
/*
th {
  background-color: #f2f2f2;
}*/
/*
tr:hover {
  background-color: #ddd;
}*/

.entry-table-container {
  overflow: visible;
  position: relative; /* Ensures table is positioned correctly for overflow */
}

.entry-table {
  z-index: 1; /* Ensures table content has a base z-index */
}
.entry-table tr {
  height: 100%;
}
.entry-table th, .entry-table td {
  height: 100%;
  padding-left: 3px;
  padding-right: 3px;
}
/* Time input field styles */
.entry-select__control {
  border-color: black !important;
}
.entry-select__control:hover {
  border-color: gray !important;
}
.entry-select__control:disabled {
  background-color: var(--custom-color-secondary);
  border-color: var(--custom-color-disabled);
  color: var(--custom-color-disabled);
}
.needs-verification.entry-select__control {
  border-color: red !important;
  background-color: #ffcccc !important;
}
.needs-verification {
  border-color: red;
  background-color: #ffcccc;
}
.info-icon {
  text-align: center;
  /*cursor: pointer;*/
  position: relative;
  color: var(--custom-color-disabled);
}
.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: black;
  color: white;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 100; /* Ensure the tooltip is above other content */
}
.entry-table .delete-button {
  margin: 0px;
}

.inactive-popup {
  position: absolute;
  top: -20px; /* Positions the popup to the top of the input */
  left: 100%; /* Positions the popup to the right of the input */
  width: auto;
  background-color: var(--popup-background-color);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  z-index: 2; /* Ensure it's above other content */
  border-radius: 5px;
}

.inactive-popup-content {
  padding: 15px;
  margin: 0px;
  font-size: 14px;
}

.inactive-popup .button.white {
  background-color: transparent;
  white-space: nowrap; /* Prevents the text from wrapping */
}
.inactive-popup .button.white:disabled {
  background-color: transparent;
  color: var(--popup-disabled-text-color);
  border-color: var(--popup-disabled-text-color);
}


.button.white {
  background-color: transparent;
  color: black;
  border: 2px solid #000000;
  margin-right: 5px;
  margin-left: 5px;
  text-transform: uppercase;
}
.button.white:hover {
  background-color: rgb(24, 24, 24);
  color: white;
  transition: 0.1s;
}
.button.white.active {
  background-color: black;
  color: white;
}
.button.white:disabled {
  background-color: #f2f2f2;
  color: #cccccc;
  border: 2px solid #cccccc;
  cursor: not-allowed;
}
nav .button.icon {
  height: 85%;
  aspect-ratio: 1.25 / 1;
  width: 30px;
  padding: 1px 5px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: 0.9;
}
nav .button.icon:hover {
  background-color: white;
  color: var(--color-brand2);
  transition: 0.1s;
}
nav .button.icon svg path { /* found path through web inspector */
  fill: white;
}
nav .button.icon .svg-inline--fa {
  width: 80%;
  height: 75%;
}
nav .button.icon:hover svg path { /* found path through web inspector */
  fill: var(--color-brand2);
}

nav .button.icon.error {
  color: var(--error-button-border-light);
  border: 2px solid var(--error-button-border-light);
}
nav .button.icon.error:hover {
  background-color: var(--error-button-border-light);
  color: white;
}
.timesaved-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
  margin-top: 0px;
}
.timesaved-text {
  background: linear-gradient(to right, var(--color-brand1-lightest), var(--color-brand2-lightest));
  color: black;
  padding: 10px; /* Adjust padding as needed */
  border-radius: 5px 0px 0px 5px; /* Optional: for rounded corners */
  display: inline-block; /* Ensure the background is only around the text */
  margin: 0px;
  box-shadow:rgba(0, 0, 0, 0.1) 0px 3px 6px; /* Optional: adds a subtle shadow */
  font-weight: 500;
}
.text-area {
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
  font-size: 16px;
  padding: 5px;
}
.integration-warning {
  background-color: transparent;
  padding: 0px;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: fit-content;
  margin-left: 50px;
  margin-right: 0px;
  z-index: 1;
  border: 1px solid var(--custom-color-secondary);
  border-top: none;
  border-radius: 0px 0px 5px 5px;
}
.integration-warning p {
  margin-top: 0;
}
.integration-icons {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  padding: 5px;
  background-color: transparent;
  border-radius: 5px;
  z-index: 1;
}
.integration-icons img {
  height: 50px;
  width: auto;
  z-index: 1;
  cursor: pointer;
}


.user-email {
  color: #302f2f; /* Dark gray */
}

.row.flex.flex-center {
  color: black;
}

.row.flex.flex-center .header {
  color: black;
}

.input-field {
  color: black;
  background-color: white;
}

.date-input {
  margin: 10px;
  font-size: 17px;
  padding: 10px;
  width: 140px;
}

.editDateWrapper {
  max-width: 250px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.button.verify {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 35px;
  height: 35px;
  font-size: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1;
  padding: 0;
  border: 1px solid black;
  background-color: #fff;
  cursor: pointer;
  z-index: 0.5;
}
.button.verify:hover {
  background-color: var(--error-red-bg);
  color: black;
}

.bill-table {
  width: 100%;
  border-collapse: collapse; /* Change this from collapse to separate */
  /*border-spacing: 10px;*/
}
.bill-table-header {
  background-color: #f2f2f2;
  text-align: left;
}

.bill-table-cell {
  border: 1px solid #ddd;
  padding: 8px;
}
.view-bill-button {
  background-color: #f2f2f2;
  color: black;
  border: 2px solid #000000;
  margin-right: 0px;
  margin-left: 5px;
}
.view-bill-button:hover {
  background-color: #000000;
  color: rgb(255, 255, 255);
  border: 2px solid #000000;
  margin-right: 0px;
  margin-left: 5px;
}

/* Time input field */
.popup {
  position: fixed; /* or absolute, depending on use-case */
  z-index: 1000; /* Ensure it's above other content */
}

.popup-inner {
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Setup/settings fields */
.input-group {
  display: flex;
  flex-direction: column;
}
.input-normal {
  width: 200px;
}

.input-error {
  width: 200px;
  border: 5px solid var(--error-red) ;
}
.error-text {
  color: var(--error-red);
}
.form-container {
  display: flex;
  align-items: center;
  flex-direction: row; /* Align items in a row */
  justify-content: flex-start; /* Left-justify the items */
  gap: 10px; /* Adds space between all child elements */

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */ /* Optional: adds a subtle shadow around the form */
}
.form-container label, .form-container input {
  display: inline-block; /* Make each label and input display inline */
  margin-right: 10px; /* Add some space between the elements */
}
/*.form-container input {
  margin-left: 10px;
}*/

/* Entries buttons */

.container {
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  align-items: flex-end;
  gap: 10px;
}

.date-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1; /* This will allow .date-settings to grow and fill the container */
}

.block-button {
  /* This ensures .block-button stays to the right and does not grow */
  flex: 0;
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: visible; /* Ensures content is not clipped */
  color: black;
}

/* EntriesCal */
.calendar-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  position: relative; /* makes it the reference point for absolutely positioned children */
}
.day-box {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 5px;
  height: 180px; /* Example fixed height */
  overflow-y: auto;
}.day-box.long {
  height: 240px; /* Example fixed height */
}
.date-header {
  background-color: var(--hover-gray); /* Light gray background */
  padding: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin: 0px -5px 10px -5px; /* Negative margins to offset the day-box padding. top, right. bottom, left */
  text-align: center; /* Center the text */
  border-radius: 0px; /* Optional: adds rounded corners */
  font-size: 15px;
  cursor: pointer;
}
.detail-box {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    width: 300px;
    z-index: 10;
    top: 20px;
    left: 20px;
    top: 25%;
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjusts the position to truly center based on its own size */
    font-size: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.detail-box div { /* Increase spacing between div elements */
  margin-top: 4px;
  margin-bottom: 4px;
}
.entry-button {
  display: block;
  width: 100%;
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 0.4;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  text-transform: none; /* Change text case to default */
  text-overflow: ellipsis;
}

.entry-button:hover {
  background-color: var(--hover-gray);
}
.entry-button.selected {
  background-color: var(--hover-gray);
}

/* Invoice generation */
.invoice-table {
  z-index: 1; /* Ensures table content has a base z-index */
}
.invoice-table, .invoice-table th, .invoice-table td {
  border: none; /* This adds the border */
  padding: 0 10px;
}
.invoice-table input {
  width: 100%;
  border: 2px solid #000;
  background-color: white;
  text-align: left;
}
.invoice-table .rounded-td {
  border: 1px solid #000; /* Example border color */
  border-radius: 5px; /* Adjust the border-radius as needed */
  padding-left: 5px; /* Provides some space inside the td */
  padding-right: 5px; /* Provides some space inside the td */
  margin: -2px; /* Adjusts for the border width to maintain layout */
  box-sizing: border-box; /* Ensures padding is included in the width */
  background-color: var(--custom-color-secondary);
}

.small-modal {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: white; /* Add your desired background color here */
  border-radius: 5px; /* Optional: Adds rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other content */
}
.medium-modal {
  width: 500px;
  max-height: 80%;
  overflow-y: auto; /* Adds scroll to modal if content is too tall */
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: white; /* Add your desired background color here */
  border-radius: 5px; /* Optional: Adds rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for depth */
  z-index: 1000; /* Ensure it's above other content */
}
.large-modal {
  width: 800px;
  max-height: 100%;
  overflow-y: auto; /* Adds scroll to modal if content is too tall */
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: white; /* Add your desired background color here */
  border-radius: 5px; /* Optional: Adds rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Optional: Adds a subtle shadow for depth */
  z-index: 1000; /* Ensure it's above other content */
}

.transaction-box {
  border: 1px solid #7c7c7c;
  background-color: #f0f0f0;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  width: auto;
  min-width: 200px;
  max-width: 100%;
}